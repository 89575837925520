import React from 'react';
import Layout from '../../layout/Layout';
import SidebarComponent from '../../components/sidebar/Sidebar';
import CollectionsComponent from '../../components/products/Collections';
import { ProvideParams } from '../../modules/NextProviders';
import { ProductsLayoutProps } from '../../types/pages/products/CollectionsTypes';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { seo } from '../../staticData/Seo';
import { ProductFilters } from '../../components/products/ProductFilters';
import { getSession } from 'next-auth/react';
import { SessionState } from '../../types/state/reducers/authReducersTypes';
import NoSearchResults from '../../components/products/NoSearchResults';
import getOwnerships from '../../hooks/getOwnerships';
import { useRouter } from 'next/router';
import SendSearchAnalyticsPlugin from '../../components/user-interest-analytics/SendSearchAnalyticsPlugin';
import productsPageHelpers from '../../helpers/productHelpers';
import { LIBRARY_IDS } from '../../constants/api';
const { LIBRARY_ID_2D } = LIBRARY_IDS;

const ProductsLayout: React.FC<ProductsLayoutProps> = (props) => {
  const {
    category,
    categories,
    collections,
    totalPages,
    currentPage,
    collectionParams,
    searchResultsCount
  } = props;

  const { ownerships } = getOwnerships();
  const collectionOwnership = ownerships;

  const router = useRouter();

  const categoryInfo = {
    name: category ? category.name : 'All Collections',
    headerImage: category ? category.header_image : '/images/collections-default.jpg',
    keywords: category ? category.keywords : seo.keywords
  };

  const headContent = {
    title: categoryInfo.name,
    keywords: categoryInfo.keywords,
    description:
      'Your one-stop-shop for premium VFX products. From fire and smoke to particles and debris, find everything you need here.',
    og: {
      title: categoryInfo.name,
      description:
        'Your one-stop-shop for premium VFX products. From fire and smoke to particles and debris, find everything you need here.',
      image: categoryInfo.headerImage
    }
  };

  return (
    <Layout head={headContent}>
      <ProvideParams>
        {({ id, slug, query }) => (
          <div className="products-container">
            <div id="products-page" className="flex flex-col md:flex-row w-full bg-[#252A2D]">
              <SidebarComponent
                id={id}
                slug={slug || 'all'}
                categories={categories.sort((a, b) => a.name.localeCompare(b.name))}
                params={collectionParams}
              />
              <div className="w-full md:min-w-[450px] p-6 min-h-screen">
                <section className="w-full h-[260px] flex items-center rounded-[5px] mb-5 bg-[url('/img/product-header.jpg')] bg-cover">
                  <div className="w-11/12 mx-auto text-center">
                    <h3 className="uppercase font-bold text-[24px] lg:text-[36px] leading-tight">
                      Browse <span className="text-a-blue">11,000+ Premium</span> VFX Assets
                    </h3>
                    <h1 className="text-[15px] lg:text-[22px] text-a-light-gray mt-4 leading-snug inter">
                      The World's Largest Library of Production-Quality VFX Stock Footage
                    </h1>
                  </div>
                </section>
                <ProductFilters
                  defaults={collectionParams}
                  popularCategories={
                    categories
                      .filter((c) =>
                        [
                          'People & Crowds',
                          'Fire',
                          'Explosions',
                          'Weather',
                          'Variety Packs'
                        ].includes(c.name)
                      )
                      .map((c) => {
                        return { name: c.name, link: c.slug, elements: c.total_elements };
                      }) as any
                  }
                />

                {collectionParams.search?.length > 0 && searchResultsCount == 0 && (
                  <NoSearchResults term={collectionParams.search} />
                )}

                {collectionParams.search?.length > 0 && (
                  <div className="mb-8 text-[#7F898D] font-light text-[14px] md:text-[16px] tracking-wide leading-[22px]">
                    Found{' '}
                    <a className="font-normal text-white">
                      {searchResultsCount}
                      {totalPages > 1 ? '+' : ''}
                    </a>{' '}
                    results for{' '}
                    <a className="font-normal text-white capitalize">{router.query.search}</a>{' '}
                  </div>
                )}

                <CollectionsComponent
                  id={id}
                  slug={slug || 'all'}
                  query={query}
                  name={categoryInfo.name}
                  collections={collections}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  collectionParams={collectionParams}
                  collectionOwnership={collectionOwnership}
                />
                <SendSearchAnalyticsPlugin search_type="products-2d" mode="productsPageSearch" />
              </div>
            </div>
          </div>
        )}
      </ProvideParams>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const session: SessionState = await getSession(ctx);
  const { fetchProductsServersideProps } = productsPageHelpers;
  return fetchProductsServersideProps(ctx, session, LIBRARY_ID_2D);
};

export default ProductsLayout;
